import React from "react";

function FeaturesSection() {
  return (
    <section className="pt-sm-2 pt-6" id="features">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <h2 className="heading-black">
              Instincts offers everything you need.
            </h2>
            <p className="text-muted lead">
              Your One-Stop Solution for Digital Excellence. From web design to
              app development, we deliver comprehensive solutions tailored to
              your needs
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-10 mx-auto">
            <div className="row feature-boxes">
              <div className="col-md-6 box">
                <div className="icon-box box-primary">
                  <div className="icon-box-inner">
                    <span data-feather="edit-3" width="35" height="35"></span>
                    <i class="fa-solid fa-laptop"></i>
                  </div>
                </div>
                <h5>Dynamic Websites</h5>
                <p className="text-muted">
                  Empower Your Online Presence: Dynamic Websites Crafted to
                  Captivate and Convert
                </p>
              </div>
              <div className="col-md-6 box">
                <div className="icon-box box-success">
                  <div className="icon-box-inner">
                    {/* <span data-feather="monitor" width="35" height="35"></span> */}
                    <i
                      class="fa-solid fa-mobile"
                    ></i>
                  </div>
                </div>
                <h5>Crafted App</h5>
                <p className="text-muted">
                  Transform Your Ideas into Stunning Apps: Crafted with
                  Precision for Ultimate User Engagement..
                </p>
              </div>
              <div className="col-md-6 box">
                <div className="icon-box box-danger">
                  <div className="icon-box-inner">
                    <span data-feather="layout" width="35" height="35"></span>
                    <i class="fa-brands fa-shopify"></i>
                  </div>
                </div>
                <h5>Shopify and Wordpress websites</h5>
                <p className="text-muted">
                  Unlock E-commerce Success: Shopify sites optimized for sales
                  and growth. Empower your online voice: WordPress sites merging
                  creativity with seamless functionality.
                </p>
              </div>
              <div className="col-md-6 box">
                <div className="icon-box box-info">
                  <div className="icon-box-inner">
                    <span data-feather="globe" width="35" height="35"></span>
                    <i class="fa-solid fa-screwdriver-wrench"></i>
                  </div>
                </div>
                <h5>Website and App maintainance</h5>
                <p className="text-muted">
                  Streamline Your Digital Maintenance: Our expert team ensures
                  peak performance for your website and app with seamless
                  updates and enhancements.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row mt-6">
          <div className="col-md-6 mr-auto">
            <h2>Knight is more than just a page builder.</h2>
            <p className="mb-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              convallis pulvinar vestibulum. Donec eleifend, sem sed dictum
              mattis, turpis purus placerat eros.
            </p>
            <a href="#" className="btn btn-light">
              Try the live demo
            </a>
          </div>
          <div className="col-md-5">
            <div className="slick-about">
              <img
                src="img/blog-1.jpg"
                className="img-fluid rounded d-block mx-auto"
                alt="Work 1"
              />
              <img
                src="img/blog-2.jpg"
                className="img-fluid rounded d-block mx-auto"
                alt="Work 2"
              />
              <img
                src="img/blog-3.jpg"
                className="img-fluid rounded d-block mx-auto"
                alt="Work 3"
              />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default FeaturesSection;
