// import logo from './logo.svg';
import './App.css';
import HomeSection from './pages/HomeSection';

function App() {
  return (
    <div className="App">
    <HomeSection />
    </div>
  );
}

export default App;
