import React from "react";
import Navbar from "./Navbar";
import AboutSection from "./AboutSection";
import FeaturesSection from "./FeaturesSection";
import PricingSection from "./PricingSection";
import FAQSection from "./FAQSection";
import ProjectSection from "./ProjectSection";
import Footer from "./Footer";

function HomeSection() {
  const scrollToFeatures = () => {
    const featuresSection = document.getElementById("features");
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home-container">
      <div>
        <Navbar />
      </div>
      <section className="bg-hero" id="home">
        <div className="container">
          <div className="row vh-md-100">
            <div className="col-md-8 col-sm-10 col-12 mx-auto my-auto text-center">
              <h1 className="heading-black text-capitalize">
                Instincts: Where Vision Meets Digital Precision!
              </h1>
              <p className="lead py-3">
                "Instincts excels in crafting digital excellence through a fusion of creativity and precision. Seamlessly bringing your vision to life, from websites to apps."
              </p>
              <button
                className="btn btn-primary d-inline-flex flex-row align-items-center"
                onClick={scrollToFeatures}
              >
                Get started now <em className="ml-2" data-feather="arrow-right"></em>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div>
        <FeaturesSection />
      </div>
      <div>
        <AboutSection />
      </div>
      <div>
        <PricingSection />
      </div>
      <div>
        <FAQSection />
      </div>
      <div>
        <ProjectSection />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default HomeSection;