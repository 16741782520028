import React from "react";

function ProjectSection() {
  return (
    <section
      className="py-7 bg-dark section-angle top-left bottom-left"
      id="blog"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <h2 className="heading-black">Projects</h2>
            <p className="text-muted lead">What's Projects at Instincts.</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a href="https://examsaathi.com/">
                <img
                  className="card-img-top img-raised"
                  src={require("../assets/examsaathi.png")}
                  alt="Blog 1"
                  height="300"
                />
              </a>
              <div className="card-body">
                <a href="https://examsaathi.com/" className="card-title mb-2">
                  <h5>Examsaathi</h5>
                </a>
                <p className="text-muted small-xl mb-2">December, 2023</p>
                <p className="card-text">
                  Your ultimate destination for comprehensive exam preparation
                  and testing. Empowering learners with a seamless online
                  platform for success.
                </p>
                <a
                  href="https://examsaathi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a href="#">
                <img
                  className="card-img-top img-raised"
                  src={require("../assets/invoicecraft.png")}
                  alt="Blog 2"
                  height="300"
                />
              </a>
              <div className="card-body">
                <a href="#" className="card-title mb-2">
                  <h5>One stop solution for Invoice</h5>
                </a>
                <p className="text-muted small-xl mb-2">October, 2023</p>
                <p className="card-text">
                  Invoice-craft includes a comprehensive range of features
                  designed to make invoicing, billing, and payment management
                  easier and more efficient.
                </p>
                <a
                  href="https://invoicecraft.co.in/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div> */}
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.anshika103.invoicecraft"
              >
                <img
                  className="card-img-top img-raised"
                  src={require("../assets/invoicecraftApp.png")}
                  alt="Blog 3"
                  height="300"
                />
              </a>
              <div className="card-body">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.anshika103.invoicecraft"
                  className="card-title mb-2"
                >
                  <h5>Invoice Craft</h5>
                </a>
                <p className="text-muted small-xl mb-2">February, 2024</p>
                <p className="card-text">
                  Simplify payment management with Invoice-craft App, offering
                  automated calculations and real-time tracking for maximum
                  efficiency and convenience.
                </p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.anshika103.invoicecraft"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a href="#">
                {/* <img
                  className="card-img-top img-raised"
                  src={require("https://play.google.com/video/lava/web/player/yt:movie:9bHivy8s6bA?autoplay=1&authuser=0&embed=play")}
                  alt="Blog 3"
                  height="300"
                /> */}
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/9bHivy8s6bA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </a>
              <div className="card-body">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.yatra.sathi&hl=en"
                  className="card-title mb-2"
                >
                  <h5>Rail Reservation Chart & PNR</h5>
                </a>
                <p className="text-muted small-xl mb-2">
                  March, 2024 last updated
                </p>
                <p className="card-text">
                  The Rail Reservation Chart & PNR system is a digital solution
                  that provides real-time information about Indian Railways at
                  your fingertips. It lets you instantly check vacant berths,
                  coach layouts, train running status, platform numbers, and
                  arrival/departure times
                </p>
                <a
                  href="https://play.google.com/store/search?q=rail+reservation+chart&c=apps&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.college.yatra"
              >
                {/* <img
                  className="card-img-top img-raised"
                  src={require("https://play.google.com/video/lava/web/player/yt:movie:9bHivy8s6bA?autoplay=1&authuser=0&embed=play")}
                  alt="Blog 3"
                  height="300"
                /> */}
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/chSUdJ9bLNs"
                  title="NEET COLLEGE, RANK PREDICTOR &amp; other details like Fees, Hostel, Bonds, State wise colleges"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </a>
              <div className="card-body">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.college.yatra"
                  className="card-title mb-2"
                >
                  <h5>NEET CUTOFF & COUNSELLOR 2023</h5>
                </a>
                <p className="text-muted small-xl mb-2">
                  Aug, 2023 last updated
                </p>
                <p className="card-text">
                  This NEET college guidance app helps students find their ideal
                  medical college by providing comprehensive information about
                  cutoff ranks, college rankings, fees, and counseling details
                  across all medical courses. The app offers personalized
                  college predictions based on your NEET AIR rank and
                  preferences, making it easier to explore and compare medical
                  institutions state-wise.
                </p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.college.yatra"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-4">
            <div className="card h-100">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.college.yatra"
              >
                {/* <img
                  className="card-img-top img-raised"
                  src={require("https://paymentapp.workfolio.tech/")}
                  alt="Blog 3"
                  height="300"
                /> */}
                <iframe
                  src="https://paymentapp.workfolio.tech/"
                  name="targetframe"
                  allowTransparency="true"
                  scrolling="no"
                  frameborder="0"
                  width="100%" height="300"
                ></iframe>
                {/* <iframe width="100%" height="300" src="https://www.youtube.com/embed/chSUdJ9bLNs" title="NEET COLLEGE, RANK PREDICTOR &amp; other details like Fees, Hostel, Bonds, State wise colleges" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
              </a>
              <div className="card-body">
                <a
                  target="_blank"
                  href="https://paymentapp.workfolio.tech/"
                  className="card-title mb-2"
                >
                  <h5>EASY PAY</h5>
                </a>
                <p className="text-muted small-xl mb-2">Aug, 2024</p>
                <p className="card-text">
                  A platform for accepting and making payments
                </p>
                <a
                  href="https://paymentapp.workfolio.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectSection;
