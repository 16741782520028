import React from "react";

function Footer() {
  return (
    <footer className="pt-6">
      <div className="container">
        <div className="row">
          <div className="col-sm-5 mr-auto">
            <h5>About Instincts</h5>
            <p className="text-muted">
              Instincts excels in crafting digital excellence through a fusion
              of creativity and precision. Seamlessly bringing your vision to
              life, from websites to apps.
            </p>
          </div>
          <div className="col-sm-2">
            <h5>Legal</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#">Privacy</a>
              </li>
              <li>
                <a href="#">Terms</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h5>Partner</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#">Refer a friend</a>
              </li>
              <li>
                <a href="#">Affiliates</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h5>Help</h5>
            <ul className="list-unstyled">
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">Refund Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 text-muted text-center small-xl">
            &copy; 2024 Instincts - All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
