import React from "react";

function FAQSection() {
  return (
    <section className="py-7" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <h2>Frequently asked questions</h2>
            <p className="text-muted lead">Answers to most common questions.</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-6 mb-5">
                <h6>Do you have any custom plan?</h6>
                <p className="text-muted">
                  Yes, we have custom plans to meet customer needs. Reach out to
                  us for more information.
                </p>
              </div>
              <div className="col-md-6 mb-5">
                <h6>Do you have hidden fees?</h6>
                <p className="text-muted">
                  No, we do not have any hidden charges. All fees are
                  transparently listed upfront.
                </p>
              </div>
              <div className="col-md-6 mb-5">
                <h6>What are the payment methods you accept?</h6>
                <p className="text-muted">
                  We accept online transactions via UPI, credit card, and debit
                  card. We also accept bank transfers.
                </p>
              </div>
              <div className="col-md-6 mb-5">
                <h6>How frequently do you update websites and projects?</h6>
                <p className="text-muted">
                  As often as necessary. We provide timely updates to ensure the
                  best user experience.
                </p>
              </div>
              <div className="col-md-6 mb-5">
                <h6>What is your refund policy?</h6>
                <p className="text-muted">
                  While we strive to ensure customer satisfaction and minimize
                  refunds, we have a flexible refund policy in place.
                </p>
              </div>
              <div className="col-md-6 mb-5">
                <h6>How can I contact you?</h6>
                <p className="text-muted">
                  You can reach us directly via LinkedIn or email. We are always
                  available to assist you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6 mx-auto text-center">
            <h5 className="mb-4">Have questions?</h5>
            <address>
              <a
                href="mailto:anshusinha872@gmail.com"
                className="btn btn-primary"
              >
                Contact Us
              </a>
            </address>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQSection;
